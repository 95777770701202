import { render, staticRenderFns } from "./VotingEvents.vue?vue&type=template&id=2b46a72a&scoped=true&"
import script from "./VotingEvents.vue?vue&type=script&lang=ts&"
export * from "./VotingEvents.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b46a72a",
  null
  
)

export default component.exports