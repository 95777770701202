





































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import ContentFactory from '@/factories/content'
import { toastMapper } from '@/store/modules/toast'
import { userMapper } from '@/store/modules/user'
import { contentStatus } from '@/config/content/contentStatus'
import _ from 'lodash'
import ContentAPI from '@/api/requests/content'
import { statusButton } from '@/config/content/statusButton'
import { Content } from '@/types/interfaces/content'
import ExcelAPI from '@/api/requests/excel'
import VoteConfirmSettingsModal from '@/components/contentManager/modal/VoteConfirmSettingsModal.vue'
import CheckVotingModal from '@/components/contentManager/modal/CheckVotingModal.vue'
import ConfirmSaveVotingModal from '@/components/contentManager/modal/ConfirmSaveVotingModal.vue'

const Mapper = Vue.extend({
  methods: {
    ...toastMapper.mapActions(['pushToast'])
  },
  computed: {
    ...userMapper.mapGetters([
      'email',
      'isCurator',
      'isAdmin',
      'isTeacher',
      'fullName',
      'userOrganizations',
      'isAuthenticated'
    ])
  }
})
@Component({
  components: {
    VoteConfirmSettingsModal,
    CheckVotingModal,
    ConfirmSaveVotingModal
  }
})
export default class VotingEvents extends Mapper {
  @Prop(Object) content!: Content
  @Prop(String) status!: string
  private voting = null as any | null
  private userMember = {} as any | object
  private isUserMember = false
  private withSettings = false
  private withTimeParams = false
  private withTextParams = false
  private timeParamsType = 'time'
  private changedInfoVariant = ''
  private changedTimeVariant = ''
  private isInputReady = true
  private isContentSaved = true
  private isEdit = statusButton.edit

  private optionsSetting = [
    { text: 'С параметрами', value: true },
    { text: 'Простое подтверждение', value: false }
  ]
  private optionsTimeSetting = [
    { text: 'Время', value: 'time' },
    { text: 'Дата', value: 'date' },
    { text: 'Дата и время', value: 'datetime' }
  ]

  @Watch('content', { deep: true })
  private onContentChange() {
    this.voting = _.cloneDeep(this.content.votingSettings)
    this.fetchUserVote()
    this.updateClientSettings()
  }

  @Watch('status', { deep: true })
  private async fetchUserVote() {
    if (this.status !== this.isEdit) {
      const vote = await ContentAPI.getUserVote(this.content.id)
      this.updateUserMember(vote)
    }
  }

  get isEditMode() {
    return this.status === this.isEdit
  }

  private updateUserMember(vote: any) {
    if (!_.isNil(vote.data.voteData)) {
      this.userMember = vote.data.voteData.voteData
    } else {
      this.userMember = {}
    }
    this.isUserMember = !_.isEmpty(this.userMember)
  }

  private async addVoting() {
    this.voting = ContentFactory.emptyVote()
    this.content.votingSettings = this.voting
    this.isContentSaved = false
  }

  private deleteVoting() {
    this.withTimeParams = false
    this.withTextParams = false
    this.withSettings = false
    this.voting = null
    this.isUserMember = false
    this.userMember = {}
    this.content.votingSettings = null
    this.isContentSaved = false
    this.$emit('contentVote', this.content)
  }

  private openAcceptVotingModal() {
    if (
      Object.keys(this.voting.settings).length === 1 &&
      this.isAuthenticated
    ) {
      this.sendAccept(null)
    }
    this.$bvModal.show('modalCheckVoting')
  }

  private async saveVote() {
    this.content.votingSettings = this.voting
    this.$emit('contentVote', this.content)
  }

  private async sendAccept(evt: any) {
    const userFIO = this.isAuthenticated ? this.fullName : evt['ФИО']
    this.userMember = {
      ...this.userMember,
      ФИО: userFIO,
      Почта: _.isEmpty(this.email) ? evt['Почта'] : this.email,
      'Класс/Организация': _.isEmpty(this.userOrganizations)
        ? [evt['Класс/Организация']]
        : this.userOrganizations.map((o: any) => o.title)
    }
    this.isUserMember = !_.isEmpty(this.userMember)
    if (this.content.status === contentStatus.publicated) {
      try {
        await ContentAPI.addVoteOnEvent(this.content.id, this.userMember)
        this.pushToast({
          title: 'Подтверждение участия',
          message: `Вы подтвердили свое участие в событии ${this.content.title}`,
          time: 5
        })
      } catch {
        this.pushToast({
          title: 'Ошибка',
          message: 'Во время подтверждения участия произошла ошибка.',
          time: 5
        })
      }
    } else {
      this.pushToast({
        title: 'Уведомление',
        message:
          'Подтверждение участия не было выполнено, т.к. данное мероприятие не опубликовано.',
        time: 5
      })
    }
    this.$bvModal.hide('modalCheckVoting')
  }

  private updateClientSettings() {
    this.withSettings = !!(
      this.voting && Object.keys(this.voting.settings).length > 1
    )
    this.withTimeParams =
      this.withSettings &&
      !!(
        this.voting.settings.time ||
        this.voting.settings.date ||
        this.voting.settings.datetime
      )
    this.withTextParams =
      this.withSettings && !_.isEmpty(this.voting.settings.fieldsNew)
    if (this.withTimeParams) {
      this.timeParamsType = this.voting.settings.time
        ? 'time'
        : this.voting.settings.date
        ? 'date'
        : 'datetime'
    }
  }

  private reloadVoteInfo(evt: any) {
    this.withSettings = evt.withSettings
    this.withTimeParams = evt.withTimeParams
    this.isInputReady = evt.isInputReady
    this.withTextParams = evt.withTextParams
    this.optionsSetting = evt.optionsSetting
    this.optionsTimeSetting = evt.optionsTimeSetting
    this.timeParamsType = evt.timeParamsType
    this.changedTimeVariant = evt.changedTimeVariant
    this.changedInfoVariant = evt.changedInfoVariant
    this.voting = evt.voting
  }

  private reloadUserVoteInfo(evt: any) {
    this.voting = evt.voting
    this.isUserMember = evt.isUserMember
    this.userMember = evt.userMember
    this.optionsTimeSetting = evt.optionsTimeSetting
  }

  private onReset(evt: any) {
    evt.preventDefault()
    this.voting = _.cloneDeep(this.content.votingSettings)
    this.updateClientSettings()
    this.$bvModal.hide('modalChangeVotingSettings')
  }

  private onSubmit(evt: any) {
    evt.preventDefault()
    if (!this.withSettings) {
      this.voting = ContentFactory.emptyVote()
      this.onConfirmSavingVoting()
    } else {
      if (!this.withTextParams) {
        delete this.voting.settings.fieldsNew
      }
      const timeKeys = this.optionsTimeSetting.map(o => o.value)
      for (const key of timeKeys) {
        if (!this.withTimeParams || key !== this.timeParamsType) {
          delete this.voting.settings[key]
        }
      }
      if (this.changedInfoVariant || this.changedTimeVariant) {
        this.$bvModal.show('modalConfirmSaveVoting')
      } else {
        this.onConfirmSavingVoting()
      }
    }
  }

  private onConfirmSavingVoting() {
    this.updateClientSettings()
    this.changedInfoVariant = ''
    this.changedTimeVariant = ''
    this.content.votingSettings = this.voting
    this.isContentSaved = false
    this.$bvModal.hide('modalChangeVotingSettings')
    this.$bvModal.hide('modalConfirmSaveVoting')
  }

  private onChangeTimeParamsType() {
    this.changedTimeVariant = ''
  }

  private exportVotingResults() {
    if (this.content.id) {
      ExcelAPI.exportVotingExcel(this.content)
    }
  }

  private isAddFieldMode() {
    return _.isEmpty(this.changedInfoVariant)
  }
}
