






























import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ConfirmSaveVotingModal extends Vue {
  private onConfirmSavingVoting() {
    this.$emit('onConfirmSavingVoting')
  }
}
