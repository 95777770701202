










































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import _ from 'lodash'
import moment from 'moment'
import dateFormat from '@/config/default/dateFormat'
import { userMapper } from '@/store/modules/user'
import ContentFactory from '@/factories/content'

const Mapper = Vue.extend({
  computed: {
    ...userMapper.mapGetters(['isAuthenticated'])
  }
})

@Component
export default class CheckVotingModal extends Mapper {
  @Prop(Boolean) isUserMember!: boolean
  @Prop(Object) voting!: any
  @Prop(Object) userMember!: any
  @Prop(Array) optionsTimeSetting!: any[]

  private isUserMemberUpdate = false
  private votingUpdate: any = {}
  private userMemberUpdate = {} as any
  private optionsTimeSettingUpdate: any[] = []

  private notAuthUserInfo = ContentFactory.defaultVote()

  private created() {
    this.votingUpdate = this.voting
    this.isUserMemberUpdate = this.isUserMember
    this.userMemberUpdate = this.userMember
    this.optionsTimeSettingUpdate = this.optionsTimeSetting
  }

  @Watch('isUserMember')
  private isUserMemberChange() {
    this.isUserMemberUpdate = this.isUserMember
  }

  @Watch('userMember')
  private userMemberChange() {
    this.userMemberUpdate = this.userMember
  }

  private reloadUserVoteInfo() {
    this.$emit('reloadUserVoteInfo', {
      voting: this.votingUpdate,
      isUserMember: this.isUserMemberUpdate,
      userMember: this.userMember,
      optionsTimeSetting: this.optionsTimeSettingUpdate
    })
  }

  private castTextForSettings(value: string, type: string) {
    if (type === 'date') {
      return moment(value).format(dateFormat.date.localFormat)
    } else if (type === 'datetime') {
      return moment(value).format(dateFormat.date.dateTime)
    } else {
      return value
    }
  }

  private optionsSelect(field: any) {
    return this.voting.settings[field].map((variant: string) => ({
      text: this.castTextForSettings(variant, field),
      value: variant
    }))
  }

  private sendAccept() {
    this.reloadUserVoteInfo()
    this.$emit('sendAccept', this.notAuthUserInfo)
  }

  get isAcceptDisabled() {
    if (this.votingUpdate) {
      if (_.isArray(this.votingUpdate.settings.fieldsNew)) {
        for (const field of this.votingUpdate.settings.fieldsNew) {
          if (!this.userMemberUpdate[field]) {
            return true
          }
        }
      }
      for (const field of this.optionsTimeSettingUpdate.map(o => o.value)) {
        if (
          _.isArray(this.votingUpdate.settings[field]) &&
          !this.userMemberUpdate[field]
        ) {
          return true
        }
      }
    }
    return false
  }
}
