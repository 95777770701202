






















import { Component, Vue } from 'vue-property-decorator'
import ContentViewer from '@/components/content/ContentViewer.vue'
import ContentFactory from '@/factories/content'
import FetchContentMixin from '@/mixins/content'
import ContentAPI from '@/api/requests/content'
import { ContentAdapter } from '@/api/adapters/content'
import { contentStatus, contentType } from '@/config/content/contentStatus'
import { OrganizationAdapter } from '@/api/adapters/organizations'
import VotingEvents from '@/components/contentManager/VotingEvents.vue'
import _ from 'lodash'
import { statusButton } from '@/config/content/statusButton'
import { userMapper } from '@/store/modules/user'

const Mapper = Vue.extend({
  computed: {
    ...userMapper.mapGetters(['isAuthenticated'])
  }
})

@Component({
  components: { ContentViewer, VotingEvents },
  mixins: [FetchContentMixin]
})
export default class EventsViewer extends Mapper {
  private pageId: number | string = this.$route.params.id
  private showSpinner = true

  private eventObject = ContentFactory.emptyEventsObject()

  private status = ''

  private changeStatusButton(value: string) {
    this.status = value
  }

  private changeContent(value: any) {
    this.eventObject = value
  }

  private getSingleContent!: Function

  private async onChangeStatus() {
    const contentId = Number(this.eventObject.id)
    this.eventObject.status =
      this.eventObject.status === contentStatus.draft
        ? contentStatus.publicated
        : contentStatus.draft

    const contentCopy = _.cloneDeep(this.eventObject)
    if (this.eventObject.type === contentType.event) {
      contentCopy.organizations = !contentCopy.organizations.find(
        (el: any) => el.title === 'Все'
      )
        ? contentCopy.organizations.map((ourOrg: any) => ourOrg.id)
        : []
    }

    const response = await ContentAPI.editContent(
      contentId,
      contentCopy,
      contentCopy.status
    )

    this.eventObject.status = response.data.content.status
  }

  private async created() {
    if (this.pageId === 'new') {
      this.eventObject = ContentFactory.emptyEventsObject()
      this.status = statusButton.edit
    } else {
      this.pageId = parseInt(this.$route.params?.id)

      let eventObjectResponse = await this.getSingleContent(
        contentType.event,
        this.pageId
      )
      eventObjectResponse.avatarUrl = ContentAdapter.adaptAvatarUrl(
        eventObjectResponse.avatarUrl
      )

      eventObjectResponse = OrganizationAdapter.adaptOrganization(
        eventObjectResponse
      )

      this.eventObject = eventObjectResponse
    }

    // Not rapid display page
    setTimeout(() => {
      this.showSpinner = false
    }, 300)
  }
}
